/* Title and Subtitles */
$tv-display: 700 32px FranceTV Brown;
$tv-h1: 300 36px FranceTV Brown;
$tv-h2: 400 30px FranceTV Brown;
$tv-h3: 400 26px FranceTV Brown;
$tv-subtitle: 400 16px FranceTV Brown;
$tv-subtitle-bold: 700 16px FranceTV Brown;
$tv-subtitle-01: 400 14px FranceTV Brown;
$tv-new-h2: 700 18px FranceTV Brown;
$tv-new-h3: 700 16px FranceTV Brown;
$tv-new-h4: 700 14px FranceTV Brown;
$tv-new-h5: 700 12px FranceTV Brown;

/* Menu and navigation */
$tv-main-menu-regular: 400 14px FranceTV Brown;
$tv-main-menu-bold: 700 14px FranceTV Brown;

/* Buttons */
$tv-text-button-large: 700 13px FranceTV Brown;
$tv-text-button-medium: 400 12px FranceTV Brown;
$tv-text-button-small: 400 10px FranceTV Brown;

/* Different text usages */
$tv-p: 400 18px FranceTV Brown;
$tv-common-text-light: 300 15px FranceTV Brown;
$tv-common-text-strong: 400 15px FranceTV Brown;
$tv-link: 700 17px FranceTV Brown;
$tv-caption: 400 12px FranceTV Brown;
$tv-caption-light: 300 12px FranceTV Brown;
$tv-tooltips-label-bold: 700 14px FranceTV Brown;
$tv-tooltips-info-regular: 400 12px FranceTV Brown;
$tv-filters-regular: 400 15px FranceTV Brown;
$tv-label-text-mat-form-field : 400 10px FranceTv #6B90B3;
$tv-input-mat-form-field : 400 11px FranceTv #001A33;

/* Different text sizes */
$tv-text-xs-light: 300 10px FranceTV Brown;
$tv-text-xs-regular: 400 10px FranceTV Brown;
$tv-text-xs-bold: 700 10px FranceTV Brown;

$tv-text-s-light: 300 12px FranceTV Brown;
$tv-text-s-regular: 400 12px FranceTV Brown;
$tv-text-s-bold: 700 12px FranceTV Brown;

$tv-text-m-light: 300 14px FranceTV Brown;
$tv-text-m-regular: 400 14px FranceTV Brown;
$tv-text-m-bold: 700 14px FranceTV Brown;

$tv-text-l-light: 300 18px FranceTV Brown;
$tv-text-l-regular: 400 18px FranceTV Brown;
$tv-text-l-bold: 700 18px FranceTV Brown;
