@import './src/assets/styles/colors';
@import './src/assets/styles/common-mixins';

.chip-error {
  background-color: $tv-error-red;
  color: $tv-white;
}
.tv-chip-success {
  background-color: $tv-success-green;
  color: $tv-white;
}
.tv-chip-warning {
  background-color: $tv-warning-yellow;
  color: $tv-white;
}
.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.whole-page-spinner-container {
  @include whole-page-spinner;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* General common styles */
.min-height-50 {
  min-height: 50px;
}

/* Flex direction */
.flex-direction-row {
  display: flex;
  flex-direction: row;
}

.flex-direction-column {
  display: flex;
  flex-direction: column;
}
